import { type ClassValue, clsx } from "clsx";
import { twMerge } from "tailwind-merge";

import { PortableTextBlock } from "@portabletext/react";
import {
  compose,
  concat,
  defaultTo,
  isEmpty,
  isNil,
  join,
  map,
  prop,
  reject,
  replace,
  toLower,
  trim,
} from "ramda";

// ----------------------------

export const isBlank = (obj: unknown) => isEmpty(obj) || isNil(obj);

export const isPresent = (obj: unknown) => !isBlank(obj);

// ----------------------------

export const certificationsList = compose(
  join(", "),
  reject(isBlank),
  map(prop("acronym")),
  defaultTo([])
);

export const emailHref = compose(concat("mailto:"), trim);

export const dateLong = () =>
  new Date().toLocaleString("en-US", {
    dateStyle: "full",
  });

export const datetimeLong = () =>
  new Date().toLocaleString("en-US", {
    dateStyle: "full",
    timeStyle: "long",
  });

export const firstParagraph = (blocks: PortableTextBlock[]) =>
  blocks[0]?.children[0]?.text;

export const phoneHref = compose(concat("tel:+1"), replace(/\D+/g, ""), trim);

export const snakeCase = compose(
  replace(/[^\w_]+/g, ""),
  replace(/ /g, "_"),
  toLower
);

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}
